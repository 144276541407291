
; require("/srv/www/html/client/modules/mobile-core/js/mobile.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var api = {};

var state = {};

var data = {};

var options = {
	listSelector: '#gallery_thumb_list'
};

var globals = mobile.globals;

var dom = {};

var setupDom = function(){

	dom.document = $(document);
	dom.thumbUl = $(options.listSelector); // '#gallery_thumb_list'
	dom.thumbLis = dom.thumbUl.find('li');
	dom.win = $(window);

};

var addEventHandlers = function(){

	dom.document.off('.slideshow');

	dom.document.on('vclick.slideshow', '.shield', function(){
		$(this).parents('li').find('img').trigger('click');
	});

	dom.document.on('vclick.slideshow', '#gallery_thumb_list img', function(){

		var pageId = globals.pageId;
		var applicationPath = typeof globals.storePath !== 'undefined' ? globals.storePath : '/';

		api.setSelectedImg(getDataIdx($(this)));

		// explicitly showing loader since we're using window.location instead of mobile.changePage below
		pbizMobile.mobile.showLoader();

		/*
			there is a mobile.changePage() bug in jquerymobile on iOS chrome (inserting hash into url unexpectedly)
		*/
		window.location = applicationPath + '?page=' + pageId + '&load=imgFull&idx=' + globals.selectedImg + '&ms=' + new Date().getTime() + '&' + globals.previewMode;

	});

	dom.document.on('vclick.slideshow', '#zoomBtn', function(e){

		var newUrl = pbizMobile.mobile.updateQueryStringParam(document.URL, 'idx', globals.selectedImg),
			imgPath = getObfuscatedImgPath(globals.imgData[globals.selectedImg].large),
			applicationPath = typeof globals.storePath !== 'undefined' ? globals.storePath : '/';

		if(pbizMobile.mobile.getUrlParam('idx') != globals.selectedImg){
			window.history.replaceState('string', 'Image', newUrl);
		}

		// explicitly showing loader since we're using window.location instead of mobile.changePage below
		pbizMobile.mobile.showLoader();

		window.location = applicationPath + '?load=imgZoom&i=' + imgPath + '&' + globals.previewMode;

		e.preventDefault();

	});

	dom.document.on('keydown.slideshow', '.imgFull', function(e){

		var key = e.which,
			isLeftArrow = key === 37,
			isRightArrow = key === 39;

		if(isLeftArrow){
			goToPrevImg();
		}
		else if(isRightArrow){
			goToNextImg();
		}

	});

	dom.document.on('swiperight.slideshow', '#imgContent', function(){
		goToPrevImg();
	});

	dom.document.on('swipeleft.slideshow', '#imgContent', function(){
		goToNextImg();
	});

	dom.document.on('vclick.slideshow', '#nextBtn', function(e){
		goToNextImg();
		e.preventDefault();
	});

	dom.document.on('vclick.slideshow', '#prevBtn', function(e){
		goToPrevImg();
		e.preventDefault();
	});

	dom.document.on('vclick.slideshow', '#zoomBtn,#prevBtn,#nextBtn', function(e){
		e.stopPropagation();
		e.preventDefault();
	});

	dom.document.on('vclick.slideshow', '#imgContent', function(){

		var $overlay = $('.imgOverlay');

		if(!$overlay.is(':visible')){
			showControls();
		}
		else {
			hideControls();
		}

	});

	dom.document.on('vclick.slideshow', '#imgOverlayHdr', function(e){

		// this is the close button (#imgOverlayHdr gives larger hit area than just the icon #imgClose)				
		var applicationPath = typeof globals.storePath !== 'undefined' ? globals.storePath : '/';

		window.location = applicationPath + '?page=' + pbizMobile.mobile.getPageId() + '&loadType=mobile&load=html' + globals.previewMode;

		pbizMobile.mobile.hideLoader();
		e.stopPropagation();

	});

	dom.win.on('scroll.slideshow', revealViewableImages);

};

var getDataIdx = function($img) {

	return parseInt($img.attr('data-idx'), 10) - 1;

};

var updateSrcAttr = function($img){

	// Instead of reading image source from data attribute, allow the
	// customer to override the medium size and dynamically grab the
	// source from the imgData array.

	var imgIdx = getDataIdx($img);
	var imgSrc = globals.imgData[imgIdx][getImgSize()];

	$('<img>').attr('src', imgSrc).load(function(){
		$img.attr('src', imgSrc);
		$img.attr('data-src', imgSrc);
		$img.addClass('revealed');
		$img.parents('li').addClass('revealed');
	});

};

var revealImg = function($img, bottomOfViewport){

	var $li = $img.parents('li'),
		topVal = $li.offset().top,
		isImgInViewableArea = bottomOfViewport > topVal;

	if(isImgInViewableArea){
		updateSrcAttr($img);
		$img.prev().animate({ opacity: 0 }, 1500);
	}

};

var revealViewableImages = function(){

	var $win = dom.win,
		$imgs = $('.ui-page-active img').filter('[src$="1px.png"]'), // only grab the images which haven't already been revealed, placeholder file name is placeholder_1px.png
		bottomOfViewport = $win.height() + $win.scrollTop(),
		$img;

	$.each($imgs, function(i, el){

		var $img = $(el);

		revealImg($img, bottomOfViewport);

	});

};

var getObfuscatedImgPath = function(path){

	var imgPath = path;

	imgPath = imgPath.replace('http://', '');
	imgPath = imgPath.replace('.jpg', '');
	imgPath = imgPath.replace('_large', '');
	imgPath = imgPath.replace(/\./g, '$x1');
	imgPath = imgPath.replace(/\//g, '$x2');
	imgPath = imgPath.replace(/\_/g, '$x3');
	imgPath = imgPath.replace(/image/g, '$x4');
	imgPath = imgPath.replace(/photobiz/g, '$x5');
	imgPath = imgPath.split(').reverse().join(');

	return imgPath;

};

var showControls = function(){
	$.cookie('showControls', true);
	$('.imgOverlay').show();
};

var hideControls = function(){
	$.cookie('showControls', false);
	$('.imgOverlay').hide();
};

var goToPrevImg = function(){

	api.setSelectedImg(parseInt(globals.selectedImg) - 1);

	if(api.getSelectedImg() < 0){
		api.setSelectedImg(globals.imgData.length - 1);
	}

	api.updateImg();

};

var goToNextImg = function(){

	api.setSelectedImg(parseInt(globals.selectedImg) + 1);

	if(api.getSelectedImg() === globals.imgData.length){
		api.setSelectedImg(0);
	}

	api.updateImg();

};

var getImgDesc = function(){
	var desc = '',
		title = globals.imgData[globals.selectedImg].title,
		caption = globals.imgData[globals.selectedImg].caption;

	if(title.length){
		desc = title;
	}
	else if(caption.length){
		desc = caption;
	}

	return desc;
};

var updateControlDisplay = function(){

	if($.cookie('showControls') == 'true'){
		$('.imgOverlay').show();
	}
	else {
		$('.imgOverlay').hide();
	}

};

var getImgSize = function() {

	// Default images size to medium, but allow customers to override
	// with js control panel hack.
	return window.mobileImgSize || 'medium';

};

var loadImg = function(src){

	$('<img />').attr('src', src).load(function(){

		pbizMobile.mobile.hideLoader();

		$('.ui-page-active #mainImg').attr('src', globals.imgData[globals.selectedImg][getImgSize()]);
		$('#imgCaption').text(getImgDesc());

	});

};


api.getSelectedImgCookie = function(){
	return $.cookie('selectedImg') || 0;
}

api.getSelectedImg = function(){
	return globals.selectedImg;
}

api.setSelectedImg = function(idx){
	globals.selectedImg = idx;
	$.cookie('selectedImg', idx);
}

api.setImgData = function(imgArr){
	globals.imgData = imgArr;
}

api.updateImg = function(index){

	var idx = typeof index !== 'undefined' ? index : globals.selectedImg,
		imgPath,
		newUrl;

	pbizMobile.mobile.showLoader();

	updateControlDisplay();

	imgPath = globals.imgData[idx][getImgSize()];

	loadImg(imgPath);

}

api.hasImages = function(){
	return globals.imgData.length && globals.imgData[0].large.indexOf('no_images') === -1;
}

api.setupGalleryView = function(){

	dom.thumbUl = $(options.listSelector);

	revealViewableImages();

}

api.init = function(){

	setupDom();

	addEventHandlers();

}

module.exports = api;
}).call(global, module, undefined, undefined);
