
; require("/srv/www/html/client/modules/mobile-core/js/mobile.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var api = {};

var state = {};

var dom = {
	document: $(document),
	menu: $('#menu')
};

var globals = mobile.globals;


var addEventHandlers = function(){

	console.log(dom.document);
	console.log(dom.document.off);

	dom.document.off('.menu');

	dom.document.on('vclick.menu', '[data-is-dropdown="true"]', function(e){

		var $a = $(this),
			isExpanded = $a.attr('data-expanded') === 'true',
			caretClass = isExpanded ? 'icon-caret-right' : 'icon-sort-down';

		$a.parents('li').find('.subMenu').toggle();
		$a.find('i').attr('class', caretClass);

		$('#menuPanel').removeClass('ui-panel-fixed');

		$a.attr('data-expanded', !isExpanded);

		e.preventDefault();

	});

	dom.document.on('panelopen.menu', function(){
		$('.biz-menu-btn .icon-reorder').attr('class', 'icon-remove');
	});

	dom.document.on('click.menu', '.icon-shopping-cart', function(){
		window.location = mobile.globals.storePath + '?load=cart';
	});

	dom.document.on('panelclose.menu', function(){
		$('.biz-menu-btn .icon-remove').attr('class', 'icon-reorder');
	});

	dom.document.on('vclick.menu', '.topLink[data-is-dropdown=false][data-is-external=false]:not(#fullSiteLink),.subLink[data-is-external=false]', function(e){ // clicking an 'endpoint'
		$('.biz-menu-btn .icon-remove').attr('class', 'icon-reorder');
		$.mobile.changePage($(this).attr('href')); // + '&ts=' + new Date().getTime());
		e.preventDefault();
	});

	dom.document.on('vclick.menu', 'a[data-is-external=true]', function(e){
		window.open($(this).attr('data-external-url'));
		e.preventDefault();
	});

};

api.updateSelectedMenuItem = function(){

	var urlArr = window.location.href.split('/'),
		friendlyStr = urlArr[urlArr.length - 1],
		friendlyArr = friendlyStr.split('?'),
		friendlyName = friendlyArr[0],
		selector = '.friendly-' + friendlyName;

	if(friendlyName === ''){
		selector = '.page-' + globals.homePageId;
	}

	dom.menu.find('li').removeClass('selected');

	$(selector).parents('li').addClass('selected');

};

api.init = function(){

	addEventHandlers();

};

module.exports = api;
}).call(global, module, undefined, undefined);
