'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
// Get access to the form widget api.
var formWidget = require('form-widget');

var showThankYou = function() {

	// The store friendly url conversion removes spaces from url params.
	// Add a pipe delimiter for spaces.
	var headerText = formWidget.submissionHeaderText.split(' ').join('|');
	var msgText = formWidget.submissionMsgText.split(' ').join('|');

	$.mobile.changePage(encodeURI("/?load=message&loadType=mobile&msgKey=formWidgetSuccess&steps=-1&hdr=" + headerText + "&msg=" + msgText + window.mobile.globals.previewMode));

};

module.exports = {

	init: function() {

		formWidget.init({
			elId: 'formWidget'/*,
			forceOneColMode: true*/
		});

		formWidget.form.off('.formWidget');

		formWidget.form.on('fbWidget-form-submit-success.formWidget', showThankYou);

	},

	destroy: function() {

		formWidget.destroy();

	}

};
